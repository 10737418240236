import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import React from "react";
import Contact from "./pages/Contact";
import Thanks from "./pages/Thanks";
import RecentWork from "./pages/RecentWork";

const mouseHandler = e => {
  const mousePos = e.clientX;
  const sWidth = window.innerWidth;
  let result = parseInt(mousePos / (sWidth / 100)) * 2;

  if (result > 100) {
    result = 200 - result;
  }
  result = 100 - result;
  document.getElementById(
    "mousey"
  ).style.backgroundColor = `rgba( 255, 255, 204, ${result / 100}`;
};

function App() {
  return (
    <div className='App' id='mousey' onMouseMove={mouseHandler}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/thanks' element={<Thanks />} />
          <Route path='/recent-work' element={<RecentWork />} />
          <Route path='*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// blue: rgb(92, 139, 147)
// grey: rgb(177, 165, 141)
// yellow: rgb(224, 200, 114)
// green: rgb(62, 105, 88)
