import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../components/title";
import WebFont from "webfontloader";

function loadFont() {
  WebFont.load({
    custom: {
      families: ["Paragraph"],
      urls: ["../index.css"],
    },
  });
}
/*
things to add to poodleform.

about me. 
examples of work

maybe put the links in a horizontal box to differentiate them from the main text


these can be separate routes from the main page?


to add to flyworld.
tab should open the 'help' menu? or at least open the menu

*/
function Home() {
  useEffect(() => {
    loadFont();
  }, []);

  return (
    <div className='titleContainer'>
      <div className='titleBox'>
        <Title />

        <p>Boutique Web Studio</p>
        {/* <p>Melbourne, Australia</p> */}
        <Link to='/contact'>Get in touch</Link>
        {/* <Link to='/contact'>About Me</Link> */}
        <Link to='/recent-work'>Recent Work</Link>
      </div>
    </div>
  );
}

export default Home;
