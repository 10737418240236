import back from "../images/back.png";
import { Link } from "react-router-dom";
const BackLink = () => {
  return (
    <Link to='/' className=' recentLinkImage'>
      <img src={back} className='backIcon' alt='arrow pointing back' />
    </Link>
  );
};

export default BackLink;
