import React, { useEffect } from "react";

function Thanks() {
  useEffect(() => {
    window.addEventListener("load", event => {
      setTimeout(() => {
        window.location.replace("http://www.poodleform.com");
      }, 2000);
    });
  });

  return (
    <div className='thanksContainer'>
      <p>Thanks for your message!</p>
      <p>We will get in touch soon.</p>
    </div>
  );
}

export default Thanks;
