import flyWorldImg from "../images/flyworld.png";
import natasha from "../images/Natasha.png";
// import back from "../images/back.png";

import BackLink from "../components/BackLink";
const RecentWork = () => {
  return (
    <div className='titleContainer '>
      {/* <Link to='/' className=' recentLinkImage'>
        <img src={back} className='backIcon' />
      </Link> */}
      <BackLink />
      <div className='recentLinks'>
        <a
          href='http://natashacantwell.com'
          target='_blank'
          rel='noopener noreferrer'
          className='recentLinkImage'
        >
          <img src={natasha} alt="Link to Natasha Cantwell's Website" />
        </a>
        <a
          href='http://flyworld.fun'
          target='_blank'
          rel='noopener noreferrer'
          className='recentLinkImage'
        >
          <img src={flyWorldImg} alt='Link to the Fly World Website' />
        </a>
      </div>
    </div>
  );
};

export default RecentWork;
