import React, { useState } from "react";

import axios from "axios";
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";

export default function ContactForm() {
  const [formStatus, setFormStatus] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
    // "g-recaptcha-response": "",
  });

  const handleChange = () => e => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const goHome = () => {
    window.location.replace("http://www.poodleform.com");
  };
  const handleSubmit = e => {
    e.preventDefault();
    document.getElementById("submitButton").style.opacity = "0.5";
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/62094c22-4ada-4876-a480-aa1524c48eb6",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          name: "",
          email: "",
          message: "",
        });
        console.log(response);
        document.getElementById("submitButton").style.opacity = "1";
        setTimeout(goHome, 3000);
        // window.location.replace("http://www.poodleform.com/thanks");
      })
      .catch(function (error) {
        document.getElementById("submitButton").style.opacity = "1";
        console.log(error);
      });
  };
  return (
    // <GoogleReCaptchaProvider reCaptchaKey='6LfSJOoUAAAAACo5FptLy5inFhJmhIPF9E9ekwsN'>
    <div className='formContainer'>
      <form className='theForm' onSubmit={handleSubmit}>
        <div className='form-group'>
          {/* <label htmlFor='name'>Name</label> */}
          <input
            type='text'
            className='form-control notMsg'
            id='name'
            placeholder='name'
            required='required'
            name='name'
            value={query.name}
            onChange={handleChange()}
          />
        </div>

        <div className='form-group'>
          {/* <label htmlFor='email' required='required'>
              Email address
            </label> */}
          <input
            type='email'
            className='form-control notMsg'
            id='email'
            placeholder='email'
            name='email'
            value={query.email}
            onChange={handleChange()}
          />
        </div>
        <div className='form-group'>
          {/* <label htmlFor='message'>Message</label> */}
          <textarea
            type='text'
            className='form-control messageBox'
            id='message'
            placeholder='message'
            required='required'
            name='message'
            value={query.message}
            onChange={handleChange()}
            rows={5}
          />
        </div>

        <div className='form-group mt-3'>
          {/* <GoogleReCaptcha
            onVerify={token => {
              setQuery({ ...query, "g-recaptcha-response": token });
            }}
          /> */}
          <button type='submit' className='submitButton' id='submitButton'>
            Submit
          </button>
        </div>
        {formStatus ? (
          <div className='success'>
            <p className='theParas'>Thanks for your message!</p>
            <p className='theParas'>We'll get in touch shortly.</p>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
    // </GoogleReCaptchaProvider>
  );
}
