import React from "react";
import ContactForm from "../components/contactForm";
// import BackLink from "../components/BackLink";
// import NavBar from "../components/navBar";

function Contact() {
  return (
    <div>
      {/* <NavBar /> */}
      <ContactForm />
    </div>
  );
}

export default Contact;
